export const GET_ALL_CATEGORIES = 'GET_ALL_CATEGORIES';
export const GET_ALL_CATEGORY_INTENTS = 'GET_ALL_CATEGORY_INTENTS';
export const GET_ALL_CATEGORY_INTENT_REQUEST =
	'GET_ALL_CATEGORY_INTENT_REQUEST';
export const FAQS_ERROR = 'FAQS_ERROR';
export const GET_ALL_INTENT_FAQS = 'GET_ALL_INTENT_FAQS';
export const ACTIVATE_INTENT = '[intnets] active intent';
export const DELETE_INTENT = '[intent] delete intent';
export const UPDATE_INTENT = '[intents] update intent';
export const INTENT_UPDATE_FAIL = '[intents] intent update fail';
export const FLIP_INTENT_STATUS = 'FLIP_INTENT_STATUS';
export const ADD_NEW_CATEGORY = 'ADD_NEW_CATEGORY';
export const ADD_NEW_INTENT = 'ADD_NEW_INTENT';
export const ADD_NEW_BULK_FAQ = 'ADD_NEW_BULK_FAQ';
export const ADD_NEW_BULK_FAQ_FAIL = 'ADD_NEW_BULK_FAQ_FAIL';
export const DELETE_INTENT_FAIL = '[Delete intent] delete intent fail';
export const GET_ALL_INTENT = '[intents] get all intents';
export const GET_ALL_INTENT_REQUEST = 'GET_ALL_INTENT_REQUEST';
export const RESET_INTENTS_STATE = 'RESET_INTENTS_STATE';
export const DELETE_FAQ = 'DELETE_FAQ';
export const UPDATE_FAQ_FAIL = '[FAQ] update faq fail';
export const DELETE_FAQ_FAIL = '[FAQ] Delete faq fail';
export const ADD_NEW_FAQ = '[faq] add new faq';
export const ADD_NEW_FAQ_FAIL = '[faq] add new faq fail';
export const UPDATE_FAQ = 'UPDATE_FAQ';
export const RESET_ACTIVE_INTENT = 'RESET_ACTIVE_INTENT';
export const DELETE_REPLY = 'DELETE_REPLY';
export const UPDATE_REPLY = 'UPDATE_REPLY';
export const UPDATE_REPLY_FAIL = '[REPLY] update reply fail';
export const DELETE_REPLY_FAIL = '[Reply] delete reply fail';
export const ADD_REPLY_FAIL = '[Reply] add reply fail';
export const ADD_REPLY = 'ADD_REPLY';
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const DELETE_INTENT_CATEGORY =
	'[BotBuilder][Intents] delete intent category';
export const DELETE_INTENT_CATEGORY_FAIL =
	'[BotBuilder][Intents] delete intent category fail';
export const ADD_NEW_CATEGORY_FAIL =
	'[BotBuilder][Intents] add new intent fail';
export const SET_ACTIVE_TAB = '[intents] set active tab';
export const SET_DEFAULT_CATEGORY = '[intents] set active category';
export const SET_DEFAULT_LANGUAGE = '[intents] set active language';

export const CHANGE_INTENT_PERCENTAGE_SUCCESS =
	'CHANGE_INTENT_PERCENTAGE_SUCCESS';

export const GET_INTENT_PERCENTAGE_SUCCESS = 'GET_INTENT_PERCENTAGE_SUCCESS';
// clear active intent
export const CLEAR_ACTIVE_INTENT_SUCCESS = 'clear_active_intent_success';
export const EXPORT_INTENTS_ACTION = 'EXPORT_INTENTS_ACTION';
export const IMPORT_INTENTS_ACTION = 'IMPORT_INTENTS_ACTION';
